import { CustomField, FieldTypeEnum, IndividualRoleEnum } from 'shared-domain';

export const individualFields: CustomField[] = [
  {
    // This field is mandatory to create an individual
    id: 'first_name',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'middle_name',
    isRequired: false,
    isEnabled: false,
  },
  {
    // This field is mandatory to create an individual
    id: 'last_name',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'maiden_name',
    isRequired: false,
    isEnabled: false,
  },
  {
    // This field is mandatory to create an individual with the "applicant" role
    id: 'email',
    type: FieldTypeEnum.email,
    isRequired: false,
    isEnabled: true,
  },
  {
    id: 'phone_number',
    type: FieldTypeEnum.tel,
    isRequired: false,
    isEnabled: false,
  },
  {
    // This field is mandatory to create an individual in a KYB
    id: 'roles',
    type: FieldTypeEnum.checkbox,
    isRequired: true,
    isEnabled: true,
    options: [
      IndividualRoleEnum.legal_representative,
      IndividualRoleEnum.shareholder,
      IndividualRoleEnum.applicant,
    ],
  },
  {
    id: 'ownership_percentage',
    type: FieldTypeEnum.number,
    isRequired: false,
    isEnabled: true,
  },
  // This field is used to link an individual to an affiliated company.
  // It should only be used if hasAffiliatedCompanies is enable in the app API config.
  // Field id must not be changed
  {
    id: 'holdings',
    type: FieldTypeEnum.affiliated,
    isRequired: false,
    isEnabled: true,
    options: [],
  },
  {
    id: 'position',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'tax_identification_number',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'social_security_number',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'birth_date',
    type: FieldTypeEnum.date,
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'birth_place',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'birth_country',
    type: FieldTypeEnum.country,
    isRequired: false,
    isEnabled: true,
  },
  {
    id: 'street_address',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'street_address_2',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'postal_code',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'city',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'state',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'region',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'country',
    nested: 'address',
    type: FieldTypeEnum.country,
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'iban',
    nested: 'banking_information',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'bic',
    nested: 'banking_information',
    isRequired: false,
    isEnabled: false,
  },
];
