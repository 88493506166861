const brandColors = {
  primary: '#27CAFF',
  secondary: '#5309CB',
};

const sidebarColors = {
  backgroundColor: '#007AFF',
  backgroundImage: 'url(./assets/sidebarBackground.png)',
  color: '#ffffff',
  dotfileLogo: '#ffffff',
};

const linkColors = {
  color: '#5d9cec',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
