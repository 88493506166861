import { BaseStepIdEnum, StepConfig, StepTypeEnum } from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true, hasSignatory: true },
  },
  // Enable the individual_edit step only for a KYC
  /*{
    id:  BaseStepIdEnum.individual_edit,
  },*/

  {
    id: 'pdf_viewer_terms',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl:
        'https://acme.onboarding.dotfile.com/assets/terms-and-conditions.pdf',
    },
  },
];
